import { ContactDetails } from "./../../../services/contact-details.service";
import {
  eTimeSlotVisabilityLevel,
  AppointmentsTimeWithLevel
} from "./../../../services/time-slot-visability.service";
import { AppointmentsService } from "./../../../services/appointments.service";
import { BarberService } from "./../../../services/get-barber-services.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Inject, Component } from "@angular/core";
import { SingleAppointment } from "../../../services/appointments.service";

@Component({
  selector: "dialog-set-appointment",
  templateUrl: "dialog-set-appointment.html",
  styleUrls: ["./weekly-plan.dialogs.css"]
})
export class DialogSetAppointment {
  selectedSingleAppointment: SingleAppointment;

  constructor(
    public dialogRef: MatDialogRef<DialogSetAppointment>,
    private appointmentsService: AppointmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedSingleAppointment = {
      date: data.date,
      name: null,
      position: data.pos,
      barberServiceType: null
    };
  }

  onContactSelected($event: ContactDetails) {
    this.selectedSingleAppointment.contactID = $event.id;
    this.selectedSingleAppointment.name = $event.name;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.appointmentsService.setAppointment(this.selectedSingleAppointment);
    this.dialogRef.close();
  }

  onServiceTypeSelected(event: BarberService) {
    this.selectedSingleAppointment.barberServiceType = event;
  }
}
