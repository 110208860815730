import { RegisterForStandbyComponent } from './../register-for-standby/register-for-standby.component';
import { ContactDetails } from './../services/contact-details.service';
import { SingleAppointment, TimeSlot } from './../services/appointments.service';
import { FormControl, Validators } from "@angular/forms";
import { BarberService } from "./../services/get-barber-services.service";
import { Component, OnChanges, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDatepickerInputEvent

} from "@angular/material";
import {
  DayAppointment,
  AppointmentsService
} from "../services/appointments.service";
import { UsersService, AppUser } from "../users.service";
import { AuthService } from "../auth.service";

import { DataService } from "../data.service";
import { ContactDetailsService } from '../services/contact-details.service';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';

@Component({
  selector: "app-appointment",
  templateUrl: "./appointment.component.html",
  styleUrls: ["./appointment.component.css"]
})
export class AppointmentComponent {
  selectedDayAppointment: DayAppointment;
  isTimeSelected = false;
  appUser: AppUser;
  freeSlots: number = 0;
  appointmentControl = new FormControl("", [Validators.required]);
  selectedService: BarberService;
  yesturday: Date;
  today: Date;
  selectedSingleAppointment:SingleAppointment;
  phone:string;
  isSetNewAppointment:boolean = false;
  isAppontmentCanceled=false;
  isShowCancelAppointmetSection = false;

  constructor(
    private data: DataService,
    private adapter: DateAdapter<any>,
    private appointmentsService: AppointmentsService,
    private usersService: UsersService,
    private auth: AuthService,
    private router:Router,
    private cds:ContactDetailsService,
    private bottomSheet: MatBottomSheet
  ) {
    this.data.setIsSpinnerNeeded(false);
    this.initDatePickerValues();
    this.initSelectedDayAppointment();
    this.updateComponentContext();
  }
  onPhoneNumberChange(value)
  {
    this.phone = value;
  }

  initDatePickerValues() {
    this.adapter.setLocale("he");
    this.yesturday = new Date();
    this.yesturday.setDate(this.yesturday.getDate() - 1);
    this.today = new Date();
  }

  initSelectedDayAppointment() {
    this.isSetNewAppointment = false;
    this.isTimeSelected = false;
    this.selectedDayAppointment = { date: null, Appontments: null };
    this.selectedSingleAppointment = {
      date: new Date(),
      name: "test",
      position:-1,
      barberServiceType:null
     
    }
  }

  updateComponentContext() {
   
    let today = new Date();
    let appDate:Date;
    this.usersService.getappUser$().subscribe(user => {
      if (!user) return;  
      this.appUser = user;
      if (this.appUser.appointment) {
        this.selectedDayAppointment.date = new Date(
                 this.appUser.date
        );
        if (this.appUser.appointment.date){
        appDate = new Date(Date.parse(this.appUser.appointment.date.toString()));
      
        if (today>appDate) 
        {
          this.selectedDayAppointment = { date: null, Appontments: null };
          this.appUser.appointment = null;
        }
      }
      }
    });
  }

  myFilter = (d: Date): boolean => {
    return this.selectDayFilter(d);

  };

  selectDayFilter(d: Date): boolean {
    let day = d.getDay();
    let first = this.today.getDate() - this.today.getDay(); // First day is the day of the month - the day of the week
    let last;
    if (this.today.getDay() >= 4) {
      last = first + 13;
    } else {
      last = first + 6;
    }

    let firstDayOfWeek: Date = new Date();
    let lastDayOfWeek: Date = new Date();
    firstDayOfWeek.setDate(first);
    firstDayOfWeek.setHours(0,0,0,0);
   
 
    lastDayOfWeek.setDate(last);
   
    return (
      d.getTime() > this.yesturday.getTime() &&
      d.getTime() < lastDayOfWeek.getTime() &&
      d.getTime() >= firstDayOfWeek.getTime()
    );
  }

  dateChangedEvent(event: MatDatepickerInputEvent<Date>) {
    this.data.setIsSpinnerNeeded(true);
    this.selectedDayAppointment.date = event.value;
    this.callGetAvailableAppointmentSlots(event.value);
   
    this.isTimeSelected = false;
  }

  countFreeSlots(selectedDayAppointment: DayAppointment) {
    this.freeSlots = 0;
    if (selectedDayAppointment.Appontments)
    {
      selectedDayAppointment.Appontments.forEach(element => {
        if(!element.isOcc && !element.isUnselectable)
        {
          
          this.freeSlots++;
        }
      });
    }
  
  }

  itemClicked(event) {
   
    
    let time =(<string>event.value).split(":");
  
    this.selectedDayAppointment.date.setHours(Number(time[0]));
    this.selectedDayAppointment.date.setMinutes(Number(time[1]));
  
    if (this.selectedDayAppointment.date < new Date())
    {
      return;
    }
   
    this.selectedSingleAppointment={
      date: new Date(this.selectedDayAppointment.date.getTime()),
      name: "NOTֹSELECTED",
      position:event.position,
      barberServiceType:this.selectedService
     
    }
  
    
    this.isTimeSelected=true;
  }

  setAppointment(){
    
    this.phone = this.phone || this.appUser.phone;
   
    if (this.phone && this.phone.length >= 10 ){
      let contact : ContactDetails;
      contact = {
        name:this.appUser.name,
        phone:this.phone,
        };
      this.cds.setContact(contact);
      this.selectedSingleAppointment.contactID = this.cds.findContactKey(contact);
    }
   
   
   
     this.selectedSingleAppointment.name=this.appUser.name;
    this.appointmentsService.setAppointment(this.selectedSingleAppointment);
    this.usersService.saveAppointment(this.selectedSingleAppointment);
    this.isSetNewAppointment && this.removeAppointment();
   
  }

  setNewAppointment()
  {
    this.initSelectedDayAppointment();
    this.isSetNewAppointment = true;
    
  }
  removeAppointment() {
    
    this.data.setIsSpinnerNeeded(true);
    if (this.appUser) {
      !this.isSetNewAppointment && this.usersService.saveAppointment(null);
      this.appointmentsService.removeAppointment(this.appUser.appointment
      );
      this.initSelectedDayAppointment();
    }
    this.isAppontmentCanceled = true;
    this.data.setIsSpinnerNeeded(false);
  }

  onServiceTypeSelected(event: BarberService) {
    this.selectedService = event;
    if(this.selectedDayAppointment.date)
    {
      this.callGetAvailableAppointmentSlots(this.selectedDayAppointment.date);
    }
  }

  registerToStandBy($event)
  {
    let registerForStandbyComponentData ={
      data:{
      selectedService:this.selectedService,
      date:this.selectedDayAppointment.date,
      name:this.appUser.name
    }
  };
    this.bottomSheet.open(RegisterForStandbyComponent,registerForStandbyComponentData);
  }

  
  callGetAvailableAppointmentSlots(date:Date)
  {
    this.data.setIsSpinnerNeeded(true);
    this.selectedDayAppointment.date = date;
    this.appointmentsService
      .getAvailableAppointmentSlots(date,this.selectedService.Duration)
      .subscribe(value => {
        this.data.setIsSpinnerNeeded(false);
        this.selectedDayAppointment.Appontments = value;
        this.countFreeSlots(this.selectedDayAppointment);
      });
    this.isTimeSelected = false;
  }

  filterAppointments(){
   
    let filterdAppointments = this.selectedDayAppointment.Appontments?this.selectedDayAppointment.Appontments.filter(x=>(this.filter(x))):[];
      this.freeSlots = filterdAppointments.length;
    
      return filterdAppointments;

  
  }

  filter(timeSlot:TimeSlot){

    let time =(<string>timeSlot.value).split(":");
    let timeSlotDate =   new Date(this.selectedDayAppointment.date);
    timeSlotDate.setHours(Number(time[0]));
    timeSlotDate.setMinutes(Number(time[1]));

      return !timeSlot.isOcc && !timeSlot.isUnselectable && new Date()<timeSlotDate;
  }

  loginRedirect()
  {
    localStorage.setItem('isRedirectToAppointment',"true");
  }

  
  showRemoveAppointment(isShowCancelAppointmetSection:boolean)
  {
    this.isShowCancelAppointmetSection = isShowCancelAppointmetSection;

  }
  
}
