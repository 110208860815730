import { Observable } from "rxjs";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { Injectable } from "@angular/core";


@Injectable()
export class NotificationService {
  private prefix = "/Notifications/";
  constructor(private db: AngularFireDatabase) {}

  getNotifications(): Observable<{}>{
    return this.db.object(this.prefix).valueChanges();
  }

  addNotification(value: string): void {
    this.db.list(this.prefix).push(value);
  }

  removeNotification(item: any) {
    this.db.list(this.prefix +"/" +item.key).remove();
  }
}
