import { forEach } from '@angular/router/src/utils/collection';
import { SoundPlayutil } from './../../util/sound-playutil';
import { TimeSlotVisabilityService, eTimeSlotVisabilityLevel } from './../../services/time-slot-visability.service';
import { DialogVacationComponent } from "./Dialogs/dialog-vacation/dialog-vacation.component";
import { ExcelExportService } from "./../../services/excel-export.service";
import {
  TimeSlot,
  AppointmentsService
} from "./../../services/appointments.service";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { Component, OnInit, Pipe, Inject, ViewChild } from "@angular/core";
import { DataService } from "../../data.service";
import { DialogShowAppointmentDetails } from "./Dialogs/DialogShowAppointmentDetails";
import { DialogSetAppointment } from "./Dialogs/DialogSetAppointment";
import { ElementRef } from "@angular/core";
import { element } from '@angular/core/src/render3/instructions';

@Component({
  selector: "app-weekly-plan",
  templateUrl: "./weekly-plan.component.html",
  styleUrls: ["./weekly-plan.component.css"]
})
export class WeeklyPlanComponent implements OnInit {
  @ViewChild("weeklyTable") weeklyTable: ElementRef;
  days: Array<Date> = [];
  dailySlots = [];
  oneDayTimeInMilisec = 1000 * 60 * 60 * 24;
  viewedDate = new Date();
  private firstDayOfWeek;
  soundUtil:SoundPlayutil = new SoundPlayutil();

  constructor(
    private ds: DataService,
    private data: DataService,
    private appointmentsService: AppointmentsService,
    public dialog: MatDialog,
    private excelService: ExcelExportService,
    private timeSlotVisabilityService:TimeSlotVisabilityService,

  ) {}

  ngOnInit() {
    this.ds.setIsSpinnerNeeded(true);
    this.getDatesOfCurrentWeek(this.viewedDate);

    let daysCount = this.days.length;
    this.days.forEach(day => {
      this.appointmentsService
        .getAppointments(day)
        .subscribe(res => this.mapAppointmentsPerDay(res, day, --daysCount));
    });
  }

  getDatesOfCurrentWeek(date: Date) {
    let day = date.getDay();
    let diff = date.getDate() - day + (day == 6 ? 7 : 0);
    let firstDayOfTheWeek = new Date(date.setDate(diff));
    this.firstDayOfWeek = firstDayOfTheWeek;
    for (let i = 0; i < 7; i++) {
      this.days[i] = new Date(
        firstDayOfTheWeek.getTime() + i * this.oneDayTimeInMilisec
      );
    }
  }

  countOcc(appDate:Date,appointment:Array<any>)
  {
    let count = 0;

    appointment && appointment.forEach(element=>{

       element.isOcc && count ++;
    })
    return count;
  }

  moveToBeginignOfDay(date:Date)
  {
    let d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    d.setHours(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  mapAppointmentsPerDay(res, day: Date, isShowSpinner) {

    let beforeCount =  this.countOcc(day,this.dailySlots[day.getDay()]);
    let afterCount =  this.countOcc(day,res || []);
    let appDate = this.moveToBeginignOfDay(day);
    let today = this.moveToBeginignOfDay(new Date());

    res.date = day;
    this.dailySlots[day.getDay()] = res || [];
      !isShowSpinner && this.ds.setIsSpinnerNeeded(false);

      if ( appDate.getTime()===today.getTime())
      {

        beforeCount<afterCount && this.playNewAppSound();
        beforeCount>afterCount && this.playRemoveAppSound();

      }




  }

  playNewAppSound()
  {
    this.soundUtil.playNewAppountmentSound();
  }

  playRemoveAppSound()
  {
    this.soundUtil.playCancelAppSound();
  }

  openCancelDialog(slot: TimeSlot, date) {
    let dialogRef = this.dialog.open(DialogShowAppointmentDetails, {
      width: "250px",
      data: { slot: slot, date: date }
    });
  }

  log(item)
  {
    console.log(item);
    debugger
  }
  openSetApp(date: Date, pos: number) {
    let dialogRef = this.dialog.open(DialogSetAppointment, {
      width: "350px",
      data: { date: date, pos: pos }
    });
  }

  swithDate(numberOfDays: number) {
    this.ds.setIsSpinnerNeeded(true);
    this.viewedDate = new Date(
      this.viewedDate.getTime() + numberOfDays * this.oneDayTimeInMilisec
    );
    this.getDatesOfCurrentWeek(this.viewedDate);
    let daysCount = this.days.length;
    this.days.forEach(day => {
      this.appointmentsService
        .getAppointments(day)
        .subscribe(res => this.mapAppointmentsPerDay(res, day, --daysCount));
    });
  }

  calcButtonHeight(duration) {
    if (duration <= 3) return duration * 3.5;
    if (duration <= 18) return duration * 3.8;
    if (duration <= 36) return duration * 3.855;
    if (duration <= 54) return duration * 3.875;
    if (duration <= 66) return duration * 3.9;
    return 4
  }

  timeSlotToText(slot: TimeSlot): string {
    return slot.name + ", " + slot.service;
  }
  isAdminSlot(slot: TimeSlot) {

    if (!slot) return null;
    return slot.level === "admin";
  }

  export() {
    this.excelService.exportAsExcelFile(
      this.weeklyTable,
      this.viewedDate.toDateString()
    );
  }

  openVacationDialog() {
    this.dialog.open(DialogVacationComponent, {
      data: this.firstDayOfWeek
    });
  }

  toggleAvailablity(day,position,value:boolean)
  {

    value?this.timeSlotVisabilityService.setTimeSlotAvailbyDay(day,position,eTimeSlotVisabilityLevel.ADMIN):this.timeSlotVisabilityService.setTimeSlotAvailbyDay(day,position,eTimeSlotVisabilityLevel.ALL);


  }
}

@Pipe({
  name: "weekDay"
})
export class DayPipe {
  transform(value: Date, string, fallback: string) {
    let days = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
    return days[value.getDay()];
  }
}

@Pipe({
  name: "maxText"
})
export class MaxTextPipe {
  transform(value: string, fallback: number) {
    if (value.length > fallback) {
      return value.substring(0, fallback - 3) + " ...";
    }
    return value;
  }
}
