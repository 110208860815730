import { Subscribe } from "@firebase/util";
import { Observable ,  BehaviorSubject } from "rxjs";

import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import { Md5 } from "ts-md5/dist/md5";
const LIMIT_OF_RECORDS = 5;

export interface ContactDetails {
  name: string;
  phone: string;
  id?: string;
}

@Injectable()
export class ContactDetailsService {
  private namesResaults: BehaviorSubject<any>;

  constructor(private db: AngularFireDatabase) {
    this.namesResaults = new BehaviorSubject({});
  }

  getContactByName(
    contactName: string,
    numberOfReccords?: number
  ): Observable<any> {
    numberOfReccords != 0 &&
      this.db
        .list("/ContactDetails")
        .query.orderByChild("name")
        .startAt(contactName)
        .endAt(contactName + "\uf8ff")
        .limitToFirst(numberOfReccords || LIMIT_OF_RECORDS)
        .on("value", res => {
          this.namesResaults.next(res.val());
        });
    return this.namesResaults;
  }

  getContactByID(id: string): Observable<any> {
    return this.db.object("/ContactDetails/" + id).valueChanges();
  }

  setContact(contactDetails: ContactDetails) {
    let hashKey = this.findContactKey(contactDetails);
    return this.db.object("/ContactDetails/" + hashKey).update(contactDetails);
  }

  removeContact(contacID: string) {
    return this.db.object("/ContactDetails/" + contacID).remove();
  }

  findContactKey(contactDetails):string
  {
    return Md5.hashStr(contactDetails.name + contactDetails.phone).toString();
  }
}
