import { SoundPlayutil } from './../../util/sound-playutil';
import { UploadUtil } from "./../../services/shared/upload-util";
import { UploadService } from "./../../services/shared/upload.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sound-manager",
  templateUrl: "./sound-manager.component.html",
  styleUrls: ["./sound-manager.component.css"]
})
export class SoundManagerComponent implements OnInit {
  soundUtil:SoundPlayutil = new SoundPlayutil();

  constructor(private upSrv: UploadService) {}

  ngOnInit() {}

  uploadfile(event, name: string) {
    let file = event.target.files[0];
    let currentUpload = new UploadUtil(file);
    currentUpload.name = name;
    this.upSrv.psuhSound(currentUpload);
  }

  playAddSound()
  {
    this.soundUtil.playNewAppountmentSound();
  }
  playRmSound()
  {
    this.soundUtil.playCancelAppSound();
  }
  addWatingSound()
  {
    debugger
    this.soundUtil.playAddWatingList();
  }
}
