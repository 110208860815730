import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

export interface Messages {
  l1:Array<string>;
  l2:Array<string>;
  l3:Array<string>;
};


@Injectable()
export class MessagesService {

  constructor(private db: AngularFireDatabase) { }

  getMessages():Observable<any> {
      return this.db.object('/Messages/').valueChanges();
  }

  setMessages(location:string,value:string):Promise<void> {
    
     return this.db.object('/Messages/').update({[location]:value});
}

}

