import { GalleryPhotosService, GalleryPhoto } from './../services/gallery-photos.service';
import { DataService } from './../data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad-gallery',
  templateUrl: './ad-gallery.component.html',
  styleUrls: ['./ad-gallery.component.css']
})
export class AdGalleryComponent implements OnInit {
  photos: GalleryPhoto[];
GalleryPhoto
  constructor(  private data: DataService,
    
    private galleryPhotosService: GalleryPhotosService) { }

  ngOnInit() {
    this.getAdPhotos();
  }

  
  getAdPhotos() {
      if (this.photos && this.photos.length > 0) return;
    this.galleryPhotosService.getAdPhotos().subscribe(res => {
      this.photos = [];
      let keys = Object.keys(res);

      keys.forEach(key => {
        this.photos.push({
          img: res[key].url,
          description: res[key].title
        });
      });
    });
  }
}
