import { Console } from '@angular/core/src/console';
import {of as observableOf,  Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import { AppUser } from "./users.service";
import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { AngularFireDatabase } from "angularfire2/database";
import "rxjs/add/observable/of";
import { SingleAppointment } from "./services/appointments.service";
import { log } from "util";
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map'


export interface t{
  name?: string;
  nickName?: string;
}

export interface AppUser {
  name: string;
  nickName?: string;
  email?: string;
  isAdmin?: boolean;
  photo?: string;
  appointment?: SingleAppointment;
  date?: number;
  phone?:string;
}



@Injectable()
export class UsersService {
  constructor(private db: AngularFireDatabase, private auth: AuthService) {}

  save(user: firebase.User) {
    this.db.object("/users/" + user.uid).update({
      name: user.displayName,
      email: user.email,
      phone: user.phoneNumber
    });
  }

  get(uid: string){
    {
      return this.db.object("/users/" + uid);
    }
  }



  getappUser$(): Observable<AppUser> {
    return this.auth.user$.switchMap(user => {
      if (user && user.uid) {
        return this.get(user.uid)
          .valueChanges().map(res => {
            res['name'] =  res['name'] || res['nickName'];
            return res;
          });
      }
      return observableOf(null);
    });
  }
  getEmptyUser(): AppUser {
    return {
      photo: "",
      email: "",
      name: "",
      isAdmin: false,
      appointment: null
    };
  }

  updateName(name: String) {
      this.auth.user$.subscribe(res => {
        
      this.db.object("/users/" + res.uid).update({
        nickName: name
      });
    });
  }

  saveAppointment(appointment: SingleAppointment) {
    this.auth.user$.subscribe(res => {
      this.db.object("/users/" + res.uid).update({
        appointment: appointment
      });
    });
  }
}
