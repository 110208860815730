import { ViewChild, ElementRef } from "@angular/core";
import { NotificationService } from "./../../services/notification.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"]
})
export class NotificationComponent implements OnInit {
  noteValue: string = "";
  isOpen: boolean = false;

  notifications = <Array<{ key: string; value: string }>>new Array();

  constructor(public ns: NotificationService) {
    ns.getNotifications().subscribe(res => {
      this.notifications = <Array<{ key: string; value: string }>>new Array();
      for (var key in res) {
        this.notifications.push({ key: key, value: res[key] });
      }
    });
  }

  ngOnInit() {}

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  addNotification() {
    this.ns.addNotification(this.noteValue);
    this.noteValue = "";
  }

  delItem(item) {
    this.ns.removeNotification(item);
  }
}
