import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // @HostListener("window:scroll", [])
  // onWindowScroll(event) {
  //   console.log("scorll pos: " + window.pageYOffset)
  //  //we'll do some stuff here when the window is scrolled
  // }

}
