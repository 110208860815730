export class SoundPlayutil {
  audioAssetsLocaiton = "https://firebasestorage.googleapis.com/v0/b/theguywiththekuku.appspot.com/o/";
  audio = new Audio();
  
  playSound(src:string)
  {
    if(!this.audio.paused) return;
    this.audio.src = this.audioAssetsLocaiton + src;
    this.audio.load();
    this.audio.play();
  }     

  playNewAppountmentSound()
  {
      this.playSound("Sounds%2FaddApp.mp3?alt=media&rd="+Math.random());
  }

  playCancelAppSound()
  {
    this.playSound("Sounds%2FrmApp%2Cmp3?alt=media&rd="+Math.random());
  }

  playAddWatingList()
  {
    this.playSound("Sounds%2FwaitListAdd.mp3?alt=media&rd="+Math.random());
  }


}
