import { AuthService } from './../../auth.service';
import { AppUser, UsersService } from './../../users.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WindowService } from './../../services/window.service';
import { Component, OnInit, Inject } from '@angular/core';
import * as firebase from 'firebase';

export class PhoneNumber {
  country: string;
  prefix: string;
  number:string;
  // format phone numbers as E.164
  get e164() {
    const num = this.country + this.number
    return `+${num}`
  }
}

@Component({
  selector: 'app-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.css']
})
export class PhoneLoginComponent implements OnInit {

  windowRef: any;
  phoneNumber = new PhoneNumber()
  verificationCode: string;
  user: any;
  username:string;
  waringText:string;
  isPassRecaptcha=false;
  warningTextObject={
    "TOO_SHORT" : "מספר הטלפון שהוכנס קצר מדי",
    "VER_ERR" : "קוד האימות שהוכנס אינו תקין",
    "RE_RECAPTCHA": "אנא סמן 'אני לא רובוט' שוב"
  }


  constructor(private win: WindowService,
   private userService: UsersService,private auth :AuthService) {

      this.phoneNumber.country = "+972";
  }

  ngOnInit() {
    let that = this;
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
           'callback': function(response) {
            that.isPassRecaptcha=true;
      },
      'expired-callback': function() {
        this.warningTextObject['RE_RECAPTCHA'];
      }
    });
    this.windowRef.recaptchaVerifier.render();

  }


  sendLoginCode() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = this.phoneNumber.e164;

    this.auth.phoneLogin(num, appVerifier,this.windowRef)
            .then(result => {
                this.windowRef.confirmationResult = result;
                this.waringText =null;
            })
            .catch( error => {this.waringText = this.warningTextObject[error.message]} );
  }

  verifyLoginCode() {
    this.windowRef.confirmationResult
                  .confirm(this.verificationCode)
                  .then( result => {
                    this.user = result.user;
                    debugger;
                    this.userService.updateName(this.username);


    })
    .catch( error => {this.waringText = this.warningTextObject['VER_ERR']});
  }

}
