import { GalleryPhoto } from './gallery-photos.service';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Observable } from 'rxjs';


export interface GalleryPhoto {
  img: string;
  description: string;
};


@Injectable()
export class GalleryPhotosService {

 
  constructor(private db: AngularFireDatabase) { }


  getPhotos():Observable<any> {
   
   return this.db.object('/GalleryPhotos/').valueChanges();
  
  }
  
  getAdPhotos():Observable<any> {
   
    return this.db.object('/Ads/GalleryPhotos/').valueChanges();
   
   }
}
