import { element } from "protractor";
import {
  GalleryPhotosService,
  GalleryPhoto
} from "./../services/gallery-photos.service";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DataService } from "../data.service";
import { debug } from "util";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"]
})
export class GalleryComponent implements OnInit {
  photos: GalleryPhoto[];
  imageReadyCount: number = 0;

  constructor(
    private data: DataService,
    public dialog: MatDialog,
    private galleryPhotosService: GalleryPhotosService
  ) {}

  ngOnInit() {
    this.getPhotos();
  }

  getPhotos() {
    this.data.setIsSpinnerNeeded(true);
    if (this.photos && this.photos.length > 0) return;
    this.galleryPhotosService.getPhotos().subscribe(res => {
      
      this.photos = [];
      let keys = Object.keys(res);

      keys.forEach(key => {
        this.photos.push({
          img: res[key].url,
          description: res[key].title
        });
      });
    });
  }

  openDialog(index: number): void {
    
    let photoInfo = this.photos[index];
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: { photoInfo },
      panelClass: "full-window-modal"

    });
  }

  photoLoadFinish() {
    this.imageReadyCount++;
    if (this.imageReadyCount >= this.photos.length) {
      this.data.setIsSpinnerNeeded(false);
    }
  }


}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "dialog-overview-example-dialog.html",
  styleUrls: ["./gallery.component.css"]
})
export class DialogOverviewExampleDialog {
  photo: GalleryPhoto;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.photo = data.photoInfo;
    console.log(this.photo);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getBackgeoundStyle(photo)
  {
    if (!photo) return {};
     let backgtoundStyle =  {
        'background-image': 'url("'+photo.img+'")'
      };

     return backgtoundStyle;
    
  }

 
}
