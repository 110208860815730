import { Data } from '@angular/router/src/config';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Component, NgModule, OnInit } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from './users.service';
import { DataService } from './data.service';

declare var device;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements  OnInit{

  isSpinnerNeeded:boolean;
 constructor(data:DataService,private userService:UsersService,private auth:AuthService,router:Router)
 {
    data.isSpinnerNeededStatus.subscribe(val=>this.isSpinnerNeeded=val);
    auth.user$.subscribe(user=>{
      if(user)
      {


        userService.save(user);
        let isFromLogin =   localStorage.getItem('isFromLogin');
        let isRedirectToAppointment =   localStorage.getItem('isRedirectToAppointment');

        if (isRedirectToAppointment=="true")
        {
          localStorage.setItem('isRedirectToAppointment',"false");
          localStorage.setItem('isFromLogin',"false");
          router.navigateByUrl ('appointment');
          return
        }

        if (isFromLogin =="true")
        {
          localStorage.setItem('isRedirectToAppointment',"false");
          localStorage.setItem('isFromLogin',"false");
          router.navigateByUrl ('');
        }


      }
    })
 }

 ngOnInit() {
  document.addEventListener("deviceready", onDeviceReady, false);
  function onDeviceReady() {
    // alert(device.platform);
  }
}


}
