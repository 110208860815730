import { Observable } from "rxjs";
import { AngularFireDatabase } from "angularfire2/database";

import { Injectable, OnChanges } from "@angular/core";
import { UploadUtil } from "./upload-util";
import * as firebase from "firebase";

@Injectable()
export class UploadService {
  private basePath: string = "/GalleryPhotos/";
  private adBasPath:string = "/Ads/GalleryPhotos/";
  private baseSoundPath:String = "/Sounds/"
  constructor(private db: AngularFireDatabase) {}

  pushUpload(upload: UploadUtil): firebase.storage.UploadTask {
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef
      .child(`${this.basePath}/${upload.file.name}`)
      .put(upload.file);
    return uploadTask;
  }

  pushAdUpload(upload: UploadUtil): firebase.storage.UploadTask {
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef
      .child(`${this.adBasPath}/${upload.file.name}`)
      .put(upload.file);
    return uploadTask;
  }

  psuhSound(upload:UploadUtil)
  {
    debugger
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef
      .child(`${this.baseSoundPath}/${upload.name}`)
      .put(upload.file);
    return uploadTask;
  }
  

  // Writes the file details to the realtime db
  saveFileDataToDB(upload: any) {
    this.db.list(this.basePath).push(upload);
  }

  getFileDataFromDB(): Observable<{}> {
    return this.db.object(this.basePath).valueChanges();
  }

  getAdFileDataFromDB(): Observable<{}> {
    return this.db.object(this.adBasPath).valueChanges();
  }


  deleteUpload(fileObject) {
    this.deleteFileData(fileObject.key)
      .then(() => {
        this.deleteFileStorage(fileObject.name);
      })
      .catch(error => console.log(error));
  }

  // Deletes the file details from the realtime db
  private deleteFileData(key) {
    return this.db.list(this.basePath).remove(key);
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name: string) {
    let storageRef = firebase.storage().ref();
    storageRef
      .child(this.basePath + "/" + name)
      .delete()
      .catch(e => {
        console.log(e);
      });
  }
}
