
import { I18nTextService } from './../i18n/i18n-text.service';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  dayOfWeek:number;
  myDate:Date; 
  tDate:Date;
  googleNavigateLink:string;
  wazeNavigateLink:string;

  constructor(private data:DataService, public i18nTextService:I18nTextService ) { 
    this.tDate=new Date();
  }

  ngOnInit() {
  
    this.dayOfWeek = new Date().getDay();
    this.data.setIsSpinnerNeeded(false);
  }

}
