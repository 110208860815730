import { element } from "protractor";
import { ErrorHandler, Injectable } from "@angular/core";
import { GlobalErrorHandlerComponent } from "./global-error-handler.component";
import { DataService } from "../../data.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private ds:DataService) {}

    errorIds={
        "ERR_0":"נראה כי התור תפוס, אנא בחר שעה אחרת"
    }

  handleError(error) {
    console.log(error);
    if(error && error.rejection && this.errorIds[error.rejection.message])
    {
        this.ds.setIsShowError(this.errorIds[error.rejection.message]);
    }
  
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    //  throw error;
  }
}
