
import { AngularFireAuth } from 'angularfire2/auth';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { UsersService } from './users.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from './data.service';
import { element } from 'protractor';

@Injectable()
export class AuthService {
  user$:Observable<firebase.User>;

  constructor(private afAuth:AngularFireAuth, private route:ActivatedRoute,private dataService:DataService) {
    this.user$ = afAuth.authState;
  }


  login():Promise<any>{


    localStorage.setItem('isFromLogin', "true");

    return this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).catch(res=>{
      if (res.code =="auth/account-exists-with-different-credential")
      {
        this.dataService.setIsShowError("נראה כי החשבון שלך קיים במערכת תחת חשבון הפייסבוק שלך. נסה להתחבר שוב באמצעות פייסבוק");
      }else if(res.code="auth/network-request-failed")
      {
        this.dataService.setIsShowError(".נראה כי אבד הקשר עם הרשת, אנא וודא כי קיים חיבור אינטרנט");
      }  else{
        this.dataService.setIsShowError(".שגיאה התרחשה באת התחברות, אנא נסה שוב מאוחר יותר");
      }
    });

   }

   facebookLogin():Promise<any>{
      localStorage.setItem('isFromLogin', "true");
      return this.afAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).catch(res=>{

        if (res.code =="auth/account-exists-with-different-credential")
        {
          this.dataService.setIsShowError("נראה כי החשבון שלך קיים במערכת תחת חשבון הגוגל שלך. נסה להתחבר שוב באמצעות גוגל");
        }else if(res.code="auth/network-request-failed")
        {
          this.dataService.setIsShowError(".נראה כי אבד הקשר עם הרשת, אנא וודא כי קיים חיבור אינטרנט");
        }  else{
          this.dataService.setIsShowError(".שגיאה התרחשה באת התחברות, אנא נסה שוב מאוחר יותר");
        }
      });
   }

   phoneLogin(num,appVerifier,windowRef) {
    localStorage.setItem('isFromLogin', "true");
   return this.afAuth.auth.signInWithPhoneNumber(num, appVerifier)
             }


  logout(){
    this.afAuth.auth.signOut();
  }



}
