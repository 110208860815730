import { DataService } from './../../data.service';
import { element } from "protractor";
import { Component, ErrorHandler, Injectable } from "@angular/core";
import { OnInit } from "@angular/core/src/metadata/lifecycle_hooks";

@Component({
  selector: "app-global-error-handler",
  templateUrl: "./global-error-handler.component.html",
  styleUrls: ["./global-error-handler.component.css"]
})

@Injectable()
export class GlobalErrorHandlerComponent  {
  errorMessage: string = ""; 
  
  

  constructor(private ds:DataService) {

    ds.isShowErrorStatus.subscribe(res=>
    this.errorMessage=res);
  }

  hideError() {
    this.ds.setIsShowError("");
 
  }

 
 
}
