import { DataService } from './../data.service';
import { map } from 'rxjs/operators';
import { MessagesService } from './../services/messages.service';
import { Component, OnInit, Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.css']
})
export class MessagesListComponent implements OnInit {



  isReady: boolean = false;
  

  messageListL1: Array<string>;
  messageListL2: Array<string>;
  messageListL3: Array<string>;

  constructor(private data:DataService, private messagesService: MessagesService) { }

  ngOnInit() {
    this.getMessages();
  }

  getMessages() {
    this.data.setIsSpinnerNeeded(true);
    this.messagesService.getMessages().subscribe(res => {

      this.messageListL1 = res.l1;
      this.messageListL2 = res.l2;
      this.messageListL3 = res.l3;
      this.data.setIsSpinnerNeeded(false);
     
    });

  }

}


