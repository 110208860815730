import { BarberService } from "./../../services/get-barber-services.service";
import { SingleAppointment, AppointmentsService } from "./../../services/appointments.service";
import { Subscribe } from "@firebase/util";
import { TimeSlotVisabilityService } from "./../../services/time-slot-visability.service";
import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-vacation-plan",
  templateUrl: "./vacation-plan.component.html",
  styleUrls: ["./vacation-plan.component.css"]
})
export class VacationPlanComponent implements OnInit {
 @Input() startDate: string; //dateTime
  public dates: Date[];
  public availabeTimeSlots;
  public startTimeSlot;
  public endTimeSlot;
  public selectedDate;
  public title = "";
  constructor(private timeSlotVisabilityService: TimeSlotVisabilityService,private appointmentsService:AppointmentsService) {}

  ngOnInit() {
    if (!this.startDate) {
      this.startDate = new Date().toString();
    }
    this.dates = [];
    for (let i = 0; i < 6; i++) {
      let datenum = Date.parse(this.startDate) + 1000 * 60 * 60 * 24 * i;
      this.dates[i] = new Date(datenum);
    }
  }

  getTimeSlotPerDay(date) {
    this.startTimeSlot=null;
    this.endTimeSlot = null;

    this.selectedDate = date;
    this.timeSlotVisabilityService
      .getAllTimeSlotsPerDay(date)
      .subscribe(res => {
        this.availabeTimeSlots = res;
      });
  }

  isShowSetVacationButton(): boolean {
    return this.startTimeSlot && this.endTimeSlot && this.selectedDate;
  }

  setVacation() {
    let startIndex = this.availabeTimeSlots.indexOf(this.startTimeSlot);
    let endIndex = this.availabeTimeSlots.indexOf(this.endTimeSlot);
    if (startIndex >= endIndex) return;
    let sa: SingleAppointment;
    let st: BarberService;
    st = {
      Duration: endIndex - startIndex+1,
      Name: " - חופשה -" + this.title,
      Price: 0
    };

    sa = {
      date: this.selectedDate,
      name: "אורן",
      position: this.availabeTimeSlots.indexOf(this.startTimeSlot),
      barberServiceType: st
    };


    this.appointmentsService.setAppointment(sa);
  
  }
}
