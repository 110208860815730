import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs'
import * as firebase from 'firebase';



@Injectable({
  providedIn: 'root'
})

export class MessagingService {
   messeging ;
  currentMessege = new BehaviorSubject(null);

  constructor(private db : AngularFireDatabase, private afAuth:AngularFireAuth) {
    if(firebase.messaging.isSupported())
      {
        this.messeging = firebase.messaging();
      }
     
   }

  private updateToken(token,date:Date)
  {
    
    this.afAuth.authState.subscribe(user=>{
      if(!user) return;
      const data = {[user.uid]:token}
      this.db.object('fcmTokens/').update(data);
      this.addToNofificationList(date,user.uid);
    })

  }

  private addToNofificationList(date:Date,uid)
  {
    let dbDate =  date.getFullYear()+":"+date.getMonth() +":"+date.getDate()
   const data = {
     
      "isNotified":false
    }
    this.db.object('fcmNotificationList/'+dbDate+"/"+uid).update(data);
  }

  public isNotified(date:Date):Observable<any>
  {
     return this.afAuth.authState.switchMap(user=>{
      if(!user) return;
      const uid = user.uid
      let dbDate =  date.getFullYear()+":"+date.getMonth() +":"+date.getDate();
      return this.db.object('fcmNotificationList/'+dbDate+"/"+uid).valueChanges().map(res=>{
        return res;
      })
        
    })
    


  }
  public removeNofificationFromList(date:Date)
  {
    let dbDate =  date.getFullYear()+":"+date.getMonth() +":"+date.getDate()
    this.afAuth.authState.subscribe(user=>{
      if(!user) return;

        this.db.object('fcmNotificationList/'+dbDate+"/"+user.uid).remove();
    });
  }
  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermissionAndUpdateDb(date:Date):Promise<void> {
     return this.messeging.requestPermission().then(()=>{
        return this.messeging.getToken();
      }).then(token=>{
        this.updateToken(token,date);
       
      })
  }

  receiveMessage()
  {
    this.messeging.onMessage((payload)=>{
      this.currentMessege.next(payload);
    });
  }
  
  isNotificationSupported()
  {
    return  ("Notification" in window);
  }
  notify(text:string)
  {
    if (!this.isNotificationSupported()) {
      alert("This browser does not support desktop notification");
    }
  
    // Let's check whether notification permissions have already been granted
    else if ((Notification as any).permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(text);
    }
  
    // Otherwise, we need to ask the user for permission
    else if ((Notification as any).permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification(text);
        }
      });
  }

}

}
