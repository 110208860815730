import { eTimeSlotVisabilityLevel, TimeSlotVisabilityService } from './../../services/time-slot-visability.service';
import { Component, OnInit,Input } from '@angular/core';
import { AppointmentsTimeWithLevel } from '../../services/time-slot-visability.service';
import { Console } from '@angular/core/src/console';

@Component({
  selector: 'app-time-slot-visability',
  templateUrl: './time-slot-visability.component.html',
  styleUrls: ['./time-slot-visability.component.css']
})
export class TimeSlotVisabilityComponent implements OnInit {
   appTimeSlot:AppointmentsTimeWithLevel;
   @Input() selectedDay:string; //dateTime
   @Input() position:number;
   @Input() visabilityLevel:string;
    isLoading = true;
   constructor(private timeSlotVisabilityService:TimeSlotVisabilityService) {

    
   }
   
  isTimeSlotAvailableToAll(){
    return  this.appTimeSlot.level == eTimeSlotVisabilityLevel.ALL;
 
  }
 
  ngOnInit() {
    let date = new Date(parseInt(this.selectedDay));
    this.appTimeSlot ={
      level:null,
      position:this.position,
      selectedDay:date.getDay()

    }
    this.timeSlotVisabilityService.getTimeSlot(date,this.position).subscribe(res=>{
      this.appTimeSlot.level = res["level"] =="admin"?eTimeSlotVisabilityLevel.ADMIN:eTimeSlotVisabilityLevel.ALL;
     this.isLoading=false;
      
    })
    
    
  }

  updateTimeSlotAvail($event) 
  {
    this.isLoading=true;

    let date = new Date(parseInt(this.selectedDay));
    if (!$event.checked)
    {
      this.timeSlotVisabilityService.setTimeSlotAvail(date,this.position,eTimeSlotVisabilityLevel.ADMIN)
      
    }
    else
    {
      this.timeSlotVisabilityService.setTimeSlotAvail(date,this.position,eTimeSlotVisabilityLevel.ALL)
      
    }

   
  
  }

}
