import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
  private isSpinnerNeeded = new BehaviorSubject<boolean>(false);
  private errorMsg = new BehaviorSubject<string>("");

  isSpinnerNeededStatus = this.isSpinnerNeeded.asObservable();
  isShowErrorStatus = this.errorMsg.asObservable();

  constructor() { }
  
  setIsSpinnerNeeded(value: boolean) {
    if (value != this.isSpinnerNeeded.getValue()) {
      this.isSpinnerNeeded.next(value);
    }
  }

  setIsShowError(value:string) {
   
      this.errorMsg.next(value);
    }
  
}
