import { StandbyService } from './services/standby.service';
import { NotificationService } from "./services/notification.service";
import { UploadService } from "./services/shared/upload.service";
import { HttpClientModule } from "@angular/common/http";
//import { GetHolidaysService } from './services/get-holidays.service';
import { DialogVacationComponent } from "./admin/weekly-plan/Dialogs/dialog-vacation/dialog-vacation.component";

import { ContactDetailsComponent } from "./admin/contact-details/contact-details.component";

import { ContactDetailsService } from "./services/contact-details.service";
import { ExcelExportService } from "./services/excel-export.service";
import { WindowService } from "./services/window.service";
import { GlobalErrorHandlerComponent } from "./global/global-error-handler/global-error-handler.component";
import { SpinnerComponent } from "./global/spinner/spinner.component";
import { AppointmentsService } from "./services/appointments.service";
import { AdminAuthGuardService } from "./admin-auth-guard.service";
import { UsersService } from "./users.service";
import { AuthService } from "./auth.service";
import { environment } from "./../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ErrorHandler } from "@angular/core";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireAuthModule } from "angularfire2/auth";
import { RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { BsNavbarComponent } from "./bs-navbar/bs-navbar.component";
import { HomeComponent } from "./home/home.component";
import {
  GalleryComponent,
  DialogOverviewExampleDialog
} from "./gallery/gallery.component";
import { AppointmentComponent } from "./appointment/appointment.component";
import { MyAppointmentsComponent } from "./my-appointments/my-appointments.component";
import { AdminAppointmentsComponent } from "./admin/admin-appointments/admin-appointments.component";
import { LoginComponent } from "./login/login.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthGuardService } from "./auth-guard.service";
import { I18nTextService } from "./i18n/i18n-text.service";
import {
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatRadioModule,
  MatButtonModule,
  MatMenuModule,
  MatIconModule,
  MatOptionModule,
  MatSelectModule,
  MatCheckboxModule,
  MatProgressBarModule,
  
  MatButtonToggleModule,
  MatExpansionModule,
  MatTabsModule,
  MatCardModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatSlideToggleModule,  
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GalleryPhotosService } from "./services/gallery-photos.service";
import { MessagesListComponent } from "./messages-list/messages-list.component";
import { MessagesService } from "./services/messages.service";

import { DataService } from "./data.service";
import { DailyPlanComponent } from "./admin/daily-plan/daily-plan.component";
import { GlobalErrorHandler } from "./global/global-error-handler/GlobalErrorHandler";
import { IntroComponent } from "./intro/intro.component";
import { BarberServiceSelectorComponent } from "./barber-service-selector/barber-service-selector.component";
import { GetBarberServicesService } from "./services/get-barber-services.service";
import { PhoneLoginComponent } from "./login/phone-login/phone-login.component";
import {
  WeeklyPlanComponent,
  DayPipe,
  MaxTextPipe
} from "./admin/weekly-plan/weekly-plan.component";
import { DialogShowAppointmentDetails } from "./admin/weekly-plan/Dialogs/DialogShowAppointmentDetails";
import { DialogSetAppointment } from "./admin/weekly-plan/Dialogs/DialogSetAppointment";
import { TimeSlotVisabilityComponent } from "./admin/time-slot-visability/time-slot-visability.component";
import { TimeSlotVisabilityService } from "./services/time-slot-visability.service";
import { PhoneInputComponent } from "./global/phone-input/phone-input.component";
import { VacationPlanComponent } from "./admin/vacation-plan/vacation-plan.component";
import { MessagingAppointmentComponent } from "./messaging-appointment/messaging-appointment.component";
import { GalleryManagerComponent } from "./admin/gallery-manager/gallery-manager.component";
import { SeviceManagerComponent } from "./admin/sevice-manager/sevice-manager.component";
import { ManegerToolsComponent } from "./admin/maneger-tools/maneger-tools.component";
import { AppointmentsClearComponent } from "./admin/appointments-clear/appointments-clear.component";
import { NotificationComponent } from "./admin/notification/notification.component";
import { AdGalleryComponent } from './ad-gallery/ad-gallery.component';
import { RegisterForStandbyComponent } from './register-for-standby/register-for-standby.component';
import { ViewSatandByComponent } from './admin/view-satand-by/view-satand-by.component';
import { SoundManagerComponent } from './admin/sound-manager/sound-manager.component';
import { FcmComponent } from './fcm/fcm.component';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    BsNavbarComponent,
    HomeComponent,
    GalleryComponent,
    AppointmentComponent,
    MyAppointmentsComponent,
    AdminAppointmentsComponent,
    LoginComponent,
    DialogOverviewExampleDialog,
    DialogShowAppointmentDetails,
    DialogSetAppointment,
    MessagesListComponent,
    SpinnerComponent,
    DailyPlanComponent,
    GlobalErrorHandlerComponent,
    IntroComponent,
    BarberServiceSelectorComponent,
    PhoneLoginComponent,
    WeeklyPlanComponent,
    DayPipe,
    MaxTextPipe,
    ContactDetailsComponent,
    TimeSlotVisabilityComponent,
    PhoneInputComponent,
    VacationPlanComponent,
    DialogVacationComponent,
    MessagingAppointmentComponent,
    GalleryManagerComponent,
    SeviceManagerComponent,
    ManegerToolsComponent,
    AppointmentsClearComponent,
    NotificationComponent,
    AdGalleryComponent,
    RegisterForStandbyComponent,
    ViewSatandByComponent,
    SoundManagerComponent,
    FcmComponent,
  
  ],
  imports: [
    
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatBadgeModule,
    MatTabsModule,
    MatCardModule,
    MatSlideToggleModule,
    

    NgbModule.forRoot(),
    RouterModule.forRoot([
      {
        path: "",
        component: HomeComponent,
        pathMatch: "full"
      },
      {
        path: "gallery",
        component: GalleryComponent,
        pathMatch: "full"
      },
      {
        path: "appointment",
        component: AppointmentComponent,
        pathMatch: "full"
      },
      {
        path: "login",
        component: LoginComponent,
        pathMatch: "full"
      },
      {
        path: "login/phone",
        component: PhoneLoginComponent,
        pathMatch: "full"
      },
      {
        path: "admin/appointments",
        component: AdminAppointmentsComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService],
        pathMatch: "full"
      },
      {
        path: "admin/daily-plan",
        component: DailyPlanComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService],
        pathMatch: "full"
      },
      {
        path: "admin/weekly-plan",
        component: WeeklyPlanComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService],
        pathMatch: "full"
      },
      {
        path: "admin/contactDetails",
        component: VacationPlanComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService],
        pathMatch: "full"
      },

      {
        path: "admin/managment",
        component: ManegerToolsComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService],
        pathMatch: "full"
      },

      {
        path: "**",
        component: HomeComponent
      }
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [
    DialogOverviewExampleDialog,
    DialogVacationComponent,
    DialogShowAppointmentDetails,
    DialogSetAppointment,
    RegisterForStandbyComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    AngularFireMessaging,
    AuthService,
    AuthGuardService,
    UsersService,
    AdminAuthGuardService,
    I18nTextService,
    GalleryPhotosService,
    MessagesService,
    AppointmentsService,
    DataService,
    GetBarberServicesService,
    WindowService,
    ExcelExportService,
    ContactDetailsService,
    TimeSlotVisabilityService,
    UploadService,
    NotificationService,
    StandbyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
