import { Injectable } from '@angular/core';

@Injectable()
export class I18nTextService {

  public strings ={
      "nav-bar-title":"הבחור עם הקוקו",
      "nav-bar-appointment-link":"תורים",
      "nav-bar-home-link":"בית"
      };
  constructor() { }

}
