import { ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';



const EXCEL_EXTENSION = '.csv';
@Injectable()
export class ExcelExportService {

  constructor() { }

  public  exportAsExcelFile(htmlObject: ElementRef, excelFileName: string): void {
    let csv = this.exportTableToCSV(htmlObject);
    this.saveAsExcelFile(csv, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    let blob = new Blob([buffer], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, fileName  +  '_export_'  + new Date().getTime() + EXCEL_EXTENSION);
  }


exportTableToCSV(htmlObject:ElementRef) {
  let csvStr="";
  let csv = [];
  let rows = htmlObject.nativeElement.querySelectorAll("table tr");
   
  for (let i = 0; i < rows.length; i++) {
      let row = [], cols = rows[i].querySelectorAll("td, th");
      
      for (let j = 0; j < cols.length; j++) {
          if(!cols[j]) continue;
          row.push(cols[j].innerText.replace(',',' '));
        }
          
      if(!row) continue;
      csv.push(row.join(","));        
  }

  csv.forEach(function(row){
    if(!row) return;
    csvStr += row + "\r\n";
  });

 return csvStr;
}

}
