
import { Injectable,Input } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

export enum eTimeSlotVisabilityLevel {
  ALL = "all",
  ADMIN = "admin"
}

export interface AppointmentsTimeWithLevel{
  selectedDay:number;
  position:number;
  level: eTimeSlotVisabilityLevel;
}

@Injectable()
export class TimeSlotVisabilityService {

  constructor(private db: AngularFireDatabase) { }


  getTimeSlot(date:Date,position:number)
  {
  return  this.db.object("AppointmentsTimeWithLevel/"+date.getDay() + "/"+ position).valueChanges();
  }

  setTimeSlotAvail(date:Date,position:number,level:eTimeSlotVisabilityLevel)
  {
  return  this.db.object("AppointmentsTimeWithLevel/"+date.getDay() + "/"+ position ).update({"level":level});
  }

  setTimeSlotAvailbyDay(dayInWeek:number,position:number,level:eTimeSlotVisabilityLevel)
  {
  return  this.db.object("AppointmentsTimeWithLevel/"+dayInWeek + "/"+ position ).update({"level":level});
  }

  getAllTimeSlotsPerDay(date:Date)
  {
    return  this.db.object("AppointmentsTimeWithLevel/"+date.getDay()).valueChanges();
  }

}
