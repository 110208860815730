import { Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css']
})
export class PhoneInputComponent implements OnInit {
  @Output() value = new EventEmitter<any>();

  phoneNumberFormControl = new FormControl('', [

    Validators.pattern("[0-9]{10}")
  ]);

  matcher = new PhoneErrorStateMatcher();

  constructor() { }

  onPhoneChange($event)
  {
    !this.phoneNumberFormControl.hasError('pattern')?
    this.value.emit($event.target.value): this.value.emit(null);
  }

  ngOnInit() {
  }

}


export class PhoneErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
