import { Router } from '@angular/router';
import { StandbyService, standBy } from './../services/standby.service';
import { Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-for-standby',
  templateUrl: './register-for-standby.component.html',
  styleUrls: ['./register-for-standby.component.css']
})
export class RegisterForStandbyComponent implements OnInit {
  customerName:string;
  selectedDate:Date;
  selectedServiceName:string;
  phoneNumber:string;
  isComplited = false;

  constructor(private bottomSheetRef: MatBottomSheetRef<RegisterForStandbyComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public standBySrv: StandbyService,
              public router:Router) {
   
  
  }

  ngOnInit() {
    this.selectedDate = this.data.date;
    this.customerName = this.data.name;
    this.selectedServiceName = this.data.selectedService["Name"];
    this.phoneNumber=null;
  
  }

  validatephoneNumber(value:string)
  {
    return value&&value.length==10;
  }

  registerForStandBy()
  {
    let standBysrvData: standBy = {
      date: this.selectedDate,
      name:this.customerName,
      phoneNumber:this.phoneNumber,
      sereviceName:this.selectedServiceName
    }
    this.standBySrv.addStandby(standBysrvData);
    this.isComplited=true;
  
  }

  openLink(event: MouseEvent): void {
    
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }




}
