

import { DataService } from "./../../data.service";
import { UploadService } from "./../../services/shared/upload.service";
import { UploadUtil } from "./../../services/shared/upload-util";
import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase";

@Component({
  selector: "app-gallery-manager",
  templateUrl: "./gallery-manager.component.html",
  styleUrls: ["./gallery-manager.component.css"]
})
export class GalleryManagerComponent implements OnInit {
  selectedFileOptions;
  title: string;
  selectedFiles: FileList;
  currentUpload: UploadUtil;

  filesOnDB: Array<any>;

  constructor(private ds: DataService, private upSvc: UploadService) {}
  ngOnInit() {
   
    this.upSvc.getFileDataFromDB().subscribe(res => {
      this.filesOnDB = [];
      let keys = [];
      let files = res;
      if(!res) return;
      Object.keys(res).map(key => {
        this.filesOnDB.push({
          key: key,
          name: files[key].name,
          url: files[key].url,
          title: files[key].title || ""
        });
      });
    });

    this.ds.setIsSpinnerNeeded(false);
  }

  detectFiles(event) {
    this.selectedFiles = event.target.files;
  }

  uploadSingle(mode:string) {
    let file = this.selectedFiles.item(0);
    this.currentUpload = new UploadUtil(file);
    let uploadTask = mode=="Ad"? this.upSvc.pushAdUpload(this.currentUpload):  this.upSvc.pushUpload(this.currentUpload);

    let dbObject = {
      url: uploadTask.snapshot.downloadURL,
      name: this.currentUpload.file.name,
      title: this.title
    };

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot: firebase.storage.UploadTaskSnapshot) => {
        // upload in progress
        this.currentUpload.progress =
          snapshot.bytesTransferred / snapshot.totalBytes * 100;
      },
      error => {
        // upload failed
        console.log(error);
      },
      () => {
        // upload success
        dbObject.url = uploadTask.snapshot.downloadURL;
        dbObject.title = dbObject.title || "";
        this.upSvc.saveFileDataToDB(dbObject);
      }
    );
  }

  deleteFiles(selectedFile) {
   
    this.upSvc.deleteUpload(selectedFile);
  }
}
