import { SoundPlayutil } from './../../util/sound-playutil';
import { StandbyService, standBy } from "./../../services/standby.service";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-view-satand-by",
  templateUrl: "./view-satand-by.component.html",
  styleUrls: ["./view-satand-by.component.css"]
})
export class ViewSatandByComponent implements OnInit {
  @Input("dateTime")
  date: number;
  isOpen = false;
  standBys: Array<standBy> = [];
  soundUtil:SoundPlayutil = new SoundPlayutil();
  
  constructor(public standBySrv: StandbyService ) {}

  ngOnInit() {
    this.standBySrv
      .getStandByByDate(new Date(Number(this.date)))
      .subscribe(res => {
        let origStandBysLength = this.standBys.length;
        this.standBys = []; 
        for (var key in res) {
          this.standBys.push(res[key]);
          res[key].key = key;
        }
        this.standBys.length>origStandBysLength && this.soundUtil.playAddWatingList();
      });
  }

  toggle()
  {

      this.isOpen = !this.isOpen && this.standBys.length>0;

  }

  
  removeItem(itemKey) {
    this.standBySrv.removeStandBysingleItem(
      new Date(Number(this.date)),
      itemKey
    );
  }
}
