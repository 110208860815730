import { AppUser, UsersService } from "./../users.service";
import { DataService } from "./../data.service";
import { MessagesService } from "./../services/messages.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-messaging-appointment",
  templateUrl: "./messaging-appointment.component.html",
  styleUrls: ["./messaging-appointment.component.css"]
})
export class MessagingAppointmentComponent implements OnInit {
  public appointmentMsg: string;
  public appUser: AppUser;
  public msgVal = "";

  constructor(
    private data: DataService,
    private messagesService: MessagesService,
    private usersService: UsersService
  ) {
    this.appUser = usersService.getEmptyUser();

    this.usersService.getappUser$().subscribe(appUser => {
      this.appUser = appUser;
    });
  }

  getMessages() {
    this.data.setIsSpinnerNeeded(true);
    this.messagesService.getMessages().subscribe(res => {
      this.appointmentMsg = res.apointmentMsg;
      this.data.setIsSpinnerNeeded(false);
    });
  }

  setMessage()
  {
    this.messagesService.setMessages("apointmentMsg",this.msgVal);
  }

  clearMessage()
  {
    this.msgVal=null;
    this.setMessage();
  }

  ngOnInit() {
    this.getMessages();
  }
}
