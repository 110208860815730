import { Component, OnInit, Input } from '@angular/core';
import { MessagingService } from '../services/messaging.service';
import { MatSlideToggleChange } from '@angular/material';

@Component({
  selector: 'app-fcm',
  templateUrl: './fcm.component.html',
  styleUrls: ['./fcm.component.css']
})
export class FcmComponent implements OnInit {
  @Input('notificationDate')
  notificationDate:Date;
  constructor(private msgService:MessagingService) { }
  
  notificationStatus:string = null;
  isNotificationOn:boolean = false;
  ngOnInit() {
    
    if (('Notification' in window) && this.msgService.messeging ) {
      this.notificationStatus = (Notification as any).permission;
    }
    this.notificationDate =  new Date(this.notificationDate);

    this.msgService.isNotified(this.notificationDate).subscribe(res=>
      {
        this.isNotificationOn = !!res;
      })
   
  }

  reminderUpdated(e:MatSlideToggleChange )
  {
    if(e.checked)
    {
      this.notificationDate = new Date(this.notificationDate);
      this.msgService.requestPermissionAndUpdateDb(this.notificationDate).then(res=>{
        this.notificationStatus = (Notification as any).permission;
      });
    }else{
      this.msgService.removeNofificationFromList(this.notificationDate);
    }
    console.log(e);
  }

}
