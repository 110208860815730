import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-dialog-vacation',
  templateUrl: './dialog-vacation.component.html',
  styleUrls: ["../weekly-plan.dialogs.css"]

})
export class DialogVacationComponent implements OnInit {

  public startDate:Date
  constructor( public dialogRef: MatDialogRef<DialogVacationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.startDate = data;
    }

  ngOnInit() {
  }

}
