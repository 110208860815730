import { JsonValue } from '@angular-devkit/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable, OnChanges } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UsersService } from './users.service';




@Injectable()
export class AdminAuthGuardService implements CanActivate{
  constructor(private usersService: UsersService, private auth:AuthService) { }
  
  canActivate():Observable<boolean>
  {
     return  this.usersService.getappUser$().pipe(map(appUser=>appUser.isAdmin));
  }

    

  }
