import { AppointmentComponent } from './../../appointment/appointment.component';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { DateAdapter, MatBottomSheet } from '@angular/material';
import { AppointmentsService } from '../../services/appointments.service';
import { UsersService } from '../../users.service';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { ContactDetailsService } from '../../services/contact-details.service';

@Component({
  selector: 'app-admin-appointments',
  templateUrl: './admin-appointments.component.html',
  styleUrls: ['./../../appointment/appointment.component.css', './admin-appointments.component.css']
})
export class AdminAppointmentsComponent extends AppointmentComponent {
  clientName: string;

  constructor(
    data: DataService,
    adapter: DateAdapter<any>,
    appointmentsService: AppointmentsService,
    usersService: UsersService,
    auth: AuthService,
    router:Router,
    cds:ContactDetailsService,
    bottomSheet: MatBottomSheet
  
  ) {
    super(data, adapter, appointmentsService, usersService, auth,router,cds,bottomSheet);

  }

  updateComponentContext() {

  }

  clientNameUpdated() {
    if (this.clientName && this.clientName.length > 0) {
      this.appUser = {
        name: this.clientName
      }
    }
  }

  setAppointment() {
 //   super.setAppointment();
    this.appUser = null;
    this.clientName = "";

  }


}
