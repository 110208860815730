export class UploadUtil {

    $key:string;
    file:File;
    url:string;
    name:string;
    progress:number;
    createdAt:Date = new Date();
    title:string;


    constructor(file:File)
    {
        this.file = file;
    }

}
