import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TimeSlot, SingleAppointment, AppointmentsService } from './../../../services/appointments.service';

import { Component, OnInit,Pipe, Inject } from "@angular/core";
import { AppointmentsTimeWithLevel, eTimeSlotVisabilityLevel } from '../../../services/time-slot-visability.service';

@Component({
    selector: 'dialog-show-appointment-details',
    templateUrl: 'dialog-show-appointment-details.html',
    styleUrls: ["./weekly-plan.dialogs.css"]
  })
  export class DialogShowAppointmentDetails {
    customerName:string;
    customerService:String;
    appointmentDate:Date;
    appointmentTime:string;
    contactId:string;
  

    constructor(
      public dialogRef: MatDialogRef<DialogShowAppointmentDetails>,
      @Inject(MAT_DIALOG_DATA) public data: any,private appointmentsService:AppointmentsService) { 
          this.customerName = data.slot.name;
          this.customerService = data.slot.service;
          this.appointmentTime = data.slot.value;
          this.appointmentDate  = data.date;
          this.contactId = data.slot.contactID;

        
          
       }
  
    onNoClick(): void {
      this.dialogRef.close();
    
    }
  
    onCancelClick(){
      let appointment: SingleAppointment;
      appointment = {
        date:  this.appointmentDate,
        name:  this.customerName,
        position: this.data.slot.position,
        barberServiceType: {
          Duration: this.data.slot.duration,
          Name: this.data.slot.name,
          Price: 0
        }
      };
  
      this.appointmentsService.removeAppointment(appointment);
      this.dialogRef.close();
    }
  }