import { ContactDetailsService } from './../../services/contact-details.service';
import { DialogSetAppointment } from './../weekly-plan/Dialogs/DialogSetAppointment';
import { DialogShowAppointmentDetails } from './../weekly-plan/Dialogs/DialogShowAppointmentDetails';
import { BarberService } from "./../../services/get-barber-services.service";
import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import {
  AppointmentsService,
  TimeSlot,
  SingleAppointment
} from "../../services/appointments.service";
import { MatDatepickerInputEvent, MatDialog } from "@angular/material";
import { AppointmentComponent } from "../../appointment/appointment.component";

@Component({
  selector: "app-daily-plan",
  templateUrl: "./daily-plan.component.html",
  styleUrls: ["./daily-plan.component.css"]
})
export class DailyPlanComponent implements OnInit {
  names: Array<string>;
  today: Date = new Date();
  dailySlots: Array<TimeSlot>;

  settingAppointment = [];
  constructor(
    private data: DataService,
    private appointmentsService: AppointmentsService, public dialog: MatDialog
  ) {}

  dateChangedEvent(event: MatDatepickerInputEvent<Date>) {
    this.data.setIsSpinnerNeeded(true);
    this.today = this.appointmentsService.date = event.value;
    this.appointmentsService
      .getAppointments(event.value)
      .subscribe(res => this.ongetFullObjectAppointments(res));
  }

  ongetFullObjectAppointments(res) {
    this.dailySlots = res || [];
    this.data.setIsSpinnerNeeded(false);
  }

  ngOnInit() {


    

    this.data.setIsSpinnerNeeded(true);
    this.appointmentsService.date = this.today;
    this.appointmentsService
      .getAppointments(this.today)
      .subscribe(res => this.ongetFullObjectAppointments(res));
  }
  

  showAppInfo(dailySlots: TimeSlot) {
    let dialogRef = this.dialog.open(DialogShowAppointmentDetails, {
      width: "250px",
      data: { slot: dailySlots, date: this.today }
    });
  }

  showSetApp(dailySlots: TimeSlot) {
    let dialogRef = this.dialog.open(DialogSetAppointment, {
      width: "250px",
      data: {date:this.today,pos:dailySlots.position}
    });
  }
}
