import { element } from "protractor";
import { AppointmentsService } from "./../../services/appointments.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-appointments-clear",
  templateUrl: "./appointments-clear.component.html",
  styleUrls: ["./appointments-clear.component.css"]
})
export class AppointmentsClearComponent implements OnInit {
  appointmentDates: Array<string>;
  startDate: string;
  endDate: string;
  dates: Array<Date>;
  succDates =[];
  failDates = [];
  constructor(private appService: AppointmentsService) {}

  ngOnInit() {}
  onEndDateChange() {
    this.dates = this.updateDateRange(this.startDate, this.endDate);
  }

  updateDateRange(start, end) {
    var arrayDates = new Array(),
      tmpDate = new Date(start),
      endDate = new Date(end);

    while (tmpDate <= end) {
      arrayDates.push(new Date(tmpDate));
      tmpDate.setDate(tmpDate.getDate() + 1);
    }

    return arrayDates;
  }

  cleanDates() {
  
    this.dates.forEach(date => {
      this.appService
        .removeAllDay(date)
        .then(res => {
         this.succDates.push(date);
        })
        .catch(err => {
          this.failDates.push(date);
        });
    });
  }
}
