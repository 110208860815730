import { forEach } from "@angular/router/src/utils/collection";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable, OnChanges } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";

export interface BarberService {
  Duration: number;
  Name: String;
  Price: number;
}

@Injectable()
export class GetBarberServicesService {
  constructor(private db: AngularFireDatabase) {}

  getBarberServicesList(): Observable<BarberService[]> {
    return this.db
      .object("/Services/")
      .valueChanges()
      .map(res => {
        return <BarberService[]>res;
      });
  }

  updateBarberServicesList(location:number,value:string):Promise<void> {
    return this.db.object("/Services/").update({[location]:value});
  }

  updateFullList(services:Array<BarberService>)
  {
    this.db.object("/Services/").set(services);
  }
}
