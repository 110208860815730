import { GetBarberServicesService,BarberService } from './../services/get-barber-services.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';


@Component({
  selector: 'app-barber-service-selector',
  templateUrl: './barber-service-selector.component.html',
  styleUrls: ['./barber-service-selector.component.css']
})
export class BarberServiceSelectorComponent implements OnInit {
  barberServices:BarberService[];

  barberServiceControl = new FormControl('', [Validators.required]);
  constructor(private barbarSeveices:GetBarberServicesService) { }

  @Output() onSelected = new EventEmitter<BarberService>();
 
  ngOnInit() {
    this.getServices();
  }

  onSelectionChange(){
    this.onSelected.emit(this.barberServiceControl.value);
    
  }
  getServices(){
    this.barbarSeveices.getBarberServicesList().subscribe(res=>{
        this.barberServices = res;
      
    })

  }
}
