
import {of as observableOf,  Observable } from 'rxjs';
import { UsersService } from './../users.service';
import { TimeSlot } from "./appointments.service";
import { BarberService } from "./get-barber-services.service";

import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";

import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map'
import { MessagingService } from './messaging.service';


export interface DayAppointment {
  date: Date;
  Appontments: Array<TimeSlot>;
}

export interface SingleAppointment {
  date: Date;
  name: string;
  position: number;
  barberServiceType: BarberService;
  contactID?:string;

}

export interface TimeSlot {
  position: number;
  value: string;
  isOcc: boolean;
  isUnselectable?:boolean;
  name?:string;
  service?:string;
  duration?:number;
  level?:string;
  contactID?:string;

}

@Injectable()
export class AppointmentsService {
  testDate: DayAppointment;
  date = new Date();
  MIN_SLOT_SIZE=2;
  lunchTime = [16,17,18,19,20,21];
  isAdmin:boolean=false;

  constructor(private db: AngularFireDatabase, private usersService: UsersService,private fcmService:MessagingService) {
    this.usersService.getappUser$().subscribe(appUser => {
        this.isAdmin= (appUser && appUser.isAdmin) || false;

         });
  }

  formantHour(date: Date) {
    let hr;
    let min;
    hr = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    min = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hr + ":" + min;
  }

  getOccupiedSlots(selectedDate: Date) {
    return this.db
      .object(
        "Appointments" +
          "/" +
          selectedDate.getDate() +
          "" +
          selectedDate.getMonth() +
          "" +
          selectedDate.getFullYear()
      )
      .valueChanges();
  }

  getAppointments(selectedDate: Date): Observable<[any]> {
    return this.db
      .list("AppointmentsTimeWithLevel" + "/" + selectedDate.getDay())
      .valueChanges()
      .switchMap(timeSlot => {
        if (timeSlot != null) {
          return this.getOccupiedSlots(selectedDate).map(occupiedSlots => {
            return this.mapTimeSlot(timeSlot , occupiedSlots, 2);
          });
        } else {
          return observableOf(null);
        }
      })
  }

  getAvailableAppointmentSlots(selectedDate: Date, duration: number ): Observable<[TimeSlot]> {
    return this.db
      .list("AppointmentsTimeWithLevel" + "/" + selectedDate.getDay())
      .valueChanges()
      .switchMap(timeSlot  => {
        if (timeSlot != null) {
          return this.getOccupiedSlots(selectedDate).map(occupiedSlots => {
            return this.mapTimeSlot(timeSlot , occupiedSlots, duration);
          });
        } else {
          return observableOf(null);
        }
      });
  }

  mapTimeSlot(    timeSlots: {}[],    occupiedSlots,    duration: number  ): Array<TimeSlot> {
    let tsArr = this.markOccupiedSlots(new Array<TimeSlot>(), timeSlots, occupiedSlots );
   // tsArr[tsArr.length-1].isUnselectable= true;
    for (let i = 0; i < tsArr.length; i++) {
      if (tsArr[i].isOcc) continue;
      if(i%1 !=0 )
      {
        tsArr[i].isUnselectable= true;
      }
      !this.checkFreeSpace(tsArr, i, duration)
        ? this.markUnselectableAsOccupied(tsArr, i, i + duration)
        : "";
    }

    return tsArr;
  }

  checkFreeSpace(    tsArr: Array<TimeSlot>,    currentIndex: number,    duration: number  ) {

    for (let i = currentIndex; i < tsArr.length && i < currentIndex + duration; i++) {
        // last slot is end of day not be ablet to select timeslot after this time.
      if(tsArr.length-1<currentIndex + duration) return false;

      if (tsArr[i].isOcc == true) {
        return false;
      }
    }
    return true;
  }

  markUnselectableAsOccupied(tsArr: Array<TimeSlot>,start: number, end: number) {
    
    for (let i = start; i < tsArr.length && i < end; i++) {

      if(tsArr[i] &&!tsArr[i].isOcc && tsArr[i].duration)
      {
        tsArr[i].isUnselectable = true;
       break;
      }
      (tsArr[i] &&!tsArr[i].isOcc) ? (tsArr[i].isUnselectable = true) : "";
    }

  }


  markPotentialHolsAsOccupied(tsArr: Array<TimeSlot>,duration:number) {

    let lastOccIndex =-1

    //allign the starting point of appointments from the last occupied possition
    for (let i=0;i<tsArr.length;i++)
    {
        if (tsArr[i].isOcc)
        {
          lastOccIndex=i;
          continue;
        }
        if (!((lastOccIndex+i)%2))
        {
          tsArr[i].isUnselectable=true;
        }
    }

    if (((duration-this.MIN_SLOT_SIZE)%2)==0)
    {
      return;
    }
    //make sure that there are 0 or 2 spaces free after selection.
    let startIndex=0;
    let count =0;
    for (let i=0;i<tsArr.length;i++)
    {
        if (!tsArr[i].isOcc)
        {
          count++
        } else {

          if ((count != duration) && count<(duration+2))
          {
              this.markUnselectableAsOccupied(tsArr,startIndex,i);
          }
          startIndex=i;
          count=0;
        }

    }


  }

  markOccupiedSlots(    tsArr: Array<TimeSlot>,    timeSlots: Array<any>, occupiedSlots  ): Array<TimeSlot> {
    for (var i = 0; i < timeSlots.length; i++) {
      tsArr.push({
        position: i,
        value: timeSlots[i] && timeSlots[i].time + "",
        isOcc:  (timeSlots[i] && timeSlots[i].level == "admin" &&  !this.isAdmin) || (occupiedSlots && occupiedSlots[i]) ? true : false,
        name:occupiedSlots && occupiedSlots[i]?occupiedSlots[i].name:'',
        service:occupiedSlots && occupiedSlots[i] && occupiedSlots[i].barberServiceType?occupiedSlots[i].barberServiceType.Name:'',
        duration:occupiedSlots && occupiedSlots[i] && occupiedSlots[i].barberServiceType?occupiedSlots[i].barberServiceType.Duration:1,
        contactID:occupiedSlots && occupiedSlots[i] ? occupiedSlots[i].contactID:'',
        level:timeSlots[i].level
       });
    }
    return tsArr;
  }

  getFullObjectAppointments(selectedDate: Date): Observable<{}> {
    return this.db
      .list("AppointmentsTimeWithLevel" + "/" + selectedDate.getDay())
      .valueChanges()
      .switchMap(timeSlot => {
        if (timeSlot != null) {
          return this.getOccupiedSlots(selectedDate).map(occupiedSlots => {
            return this.mapFullObjectTimeSlot(timeSlot, occupiedSlots);
          });
        } else {
          return observableOf(null);
        }
      });
  }

  mapFullObjectTimeSlot(timeSlot, occupiedSlots) {
    occupiedSlots = occupiedSlots || [];
    type SingleResObj = {
      timeSlot?: string;
      name?: string;
    };

    let responseArray = new Array<{}>();
    if (occupiedSlots) {
      for (let i = 0; i < timeSlot.length; i++) {
        let singleResObj: SingleResObj = {};
        singleResObj.timeSlot = timeSlot[i];
        if (occupiedSlots[timeSlot[i]]) {
          singleResObj.name = occupiedSlots[timeSlot[i]].name;
        }

        responseArray.push(singleResObj);
      }
    }
    return responseArray;
  }

  setAppointment(singleAppointment: SingleAppointment): Promise<void> {
    let updateData = {};
    updateData[singleAppointment.position] =
      singleAppointment;

    for (let i = 1; i < singleAppointment.barberServiceType.Duration; i++) {
      updateData[singleAppointment.position + i + ""] = {
        Duration: 1,
        Name: "PLACE_HOLDER_"+singleAppointment.position,
       };
    }

    return this.db
      .object(
        "/Appointments/" +
          singleAppointment.date.getDate() +
          "" +
          singleAppointment.date.getMonth() +
          "" +
          singleAppointment.date.getFullYear() +
          "/"
      )
      .update(updateData);
  }

  removeAppointment(appointment:SingleAppointment) {
    let date = new Date(appointment.date);
    let appontmentDBDateLocation =   "/Appointments/" +    date.getDate() +    "" +    date.getMonth() +    "" +    date.getFullYear();
    let removeTimeSlot = [];

    for (let i = 0; i < appointment.barberServiceType.Duration; i++) {
      this.db
    .object(appontmentDBDateLocation+"/" + (appointment.position+i)).remove();

    }

    this.fcmService.removeNofificationFromList(date);
  }

  getAllAppooimtment():Observable<{}>
  {
    return this.db
    .object(
      "/Appointments/").valueChanges();
  }

  removeAllDay(date:Date):Promise<any>
  {
    let appontmentDBDateLocation =   "/Appointments/" +    date.getDate() +    "" +    date.getMonth() +    "" +    date.getFullYear();
    return this.db.object(appontmentDBDateLocation).remove();
  }

  getHours(index: number): number {
    return index / 2 + 10;
  }

  getMinutes(index: number): number {
    return (index % 2) * 30;
  }
}
