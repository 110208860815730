import { OnChanges, Output, Input, EventEmitter } from "@angular/core";
import {
  ContactDetails,
  ContactDetailsService
} from "./../../services/contact-details.service";
import { Component, OnInit } from "@angular/core";
import { SimpleChanges } from "@angular/core/src/metadata/lifecycle_hooks";
import { concat } from "rxjs/operators";

@Component({
  selector: "app-contact-details",
  templateUrl: "./contact-details.component.html",
  styleUrls: ["./contact-details.component.css"]
})
export class ContactDetailsComponent implements OnInit {
  contactRes: Array<ContactDetails>;
  newContact: ContactDetails = { name: "", phone: "" };
  isShowAddCustomer = false;
  selectedContact: ContactDetails;
  selectedContactName:string;
 
  @Output() onContactSelected = new EventEmitter<ContactDetails>();
  @Input('contactName') contactName: string ="";
  @Input('contactId') contactId: string ="";


  constructor(private cds: ContactDetailsService) {
    this.cds.getContactByName("", 0).subscribe(res => {
      this.contactRes = [];

      if (!res) return;
      for (let key in res) {
        this.contactRes.push({
          id: key,
          name: res[key].name,
          phone: res[key].phone
        });
      }
    });
  }
  deleteContact(id:string) {
    this.cds.removeContact(id).then(res=>{
      this.selectedContact = null;
    });
  }
  searchContact(name:string) {
    this.contactName &&
    this.cds.getContactByName(this.contactName, 4);
  }

  ngOnChanges(changes: SimpleChanges) {
    changes.contactName &&  this.selectedContactName!=changes.contactName.currentValue &&
    this.searchContact(changes.contactName.currentValue);
  }
 
  onSelectedContact(contact:ContactDetails)
  {
    this.selectedContactName = contact.name;
    this.onContactSelected.emit(contact);
  }

  setNewContact() {
    this.newContact.name =  this.contactName 
    this.cds.setContact(this.newContact).then(res => {
      this.searchContact(this.contactName);
      this.selectedContact = null;
      this.newContact.name = "";
      this.newContact.phone = "";

    
    });
  }
  ngOnInit() {
    this.contactId &&  this.cds.getContactByID(this.contactId).subscribe(res=>{
      this.selectedContact = res;
      
    });

  }
}
