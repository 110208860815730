import { element } from "protractor";
import { GetBarberServicesService } from "./../../services/get-barber-services.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sevice-manager",
  templateUrl: "./sevice-manager.component.html",
  styleUrls: ["./sevice-manager.component.css"]
})
export class SeviceManagerComponent implements OnInit {
  serviceList: Array<any>;
  status: Array<number>;

  newService = {
    Duration: 0,
    Name: "",
    Price: 0
  };

  constructor(public barberService: GetBarberServicesService) {}

  ngOnInit() {
    this.barberService.getBarberServicesList().subscribe(res => {
      this.serviceList = res;
      this.status = new Array(res.length);
    });
  }

  onEditClick(selectedService, index) {
    this.status = new Array(this.serviceList.length);

    this.status[index] = 0;
    this.barberService
      .updateBarberServicesList(index, selectedService)
      .catch(e => {
        this.status[index] = 2;
      })
      .then(res => {
        this.status[index] = 1;
      });
  }

  onAddClick() {
    this.newService &&
      this.newService.Duration &&
      this.newService.Name &&
      this.newService.Price &&
      this.onEditClick(this.newService, this.serviceList.length);

    this.newService = {
      Duration: 0,
      Name: "",
      Price: 0
    };
  }

  onDeleteClick(index)
  {
    this.serviceList.splice(index,1);
    this.barberService.updateFullList(this.serviceList);
    
  }
}
