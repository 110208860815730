import { Router } from "@angular/router";
import { UsersService, AppUser } from "./../users.service";
import { AuthService } from "./../auth.service";
import { AngularFireAuth } from "angularfire2/auth";
import { Component, OnInit, NgModule, ElementRef } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
  style,
  animate,
  trigger,
  state,
  transition
} from "@angular/animations";
import { I18nTextService } from "../i18n/i18n-text.service";

@Component({
  selector: "bs-navbar",
  templateUrl: "./bs-navbar.component.html",
  styleUrls: ["./bs-navbar.component.css"],

  host: {
    "(document:click)": "onClick($event)"
  }
})
@NgModule({
  declarations: [BsNavbarComponent],
  imports: [NgbModule]
})
export class BsNavbarComponent {
  public isCollapsed = true;
  public appUser: AppUser;
  loggedUser;

  constructor(
    public auth: AuthService,
    private usersService: UsersService,
    public i18nTextService: I18nTextService,
    private _eref: ElementRef,
    private router: Router
  ) {
    this.loggedUser = this.appUser = usersService.getEmptyUser();

    this.usersService.getappUser$().subscribe(appUser => {
      this.loggedUser = appUser
        ? (this.appUser = appUser)
        : (this.appUser = this.appUser = usersService.getEmptyUser());
    });
  }

  logoutClick(event) {
    this.isCollapsed = !this.isCollapsed;
    this.auth.logout();
  }

  onClick($event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.isCollapsed = true;
    }
  }

  isTaskBarNeeded(): boolean {
    switch (this.router.url) {
     case  "/":
     {
       return true;
     }
     case "/gallery":{
       return true;
     }
    }

    return false;
  }
}
