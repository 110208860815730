import { Subscribe } from '@firebase/util';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from 'angularfire2/database';
import { Injectable } from '@angular/core';


export interface standBy {
  date: Date;
  numericDate?:number;
  name:string;
  phoneNumber:string;
  sereviceName:string;
}


@Injectable()
export class StandbyService {
  private prefix = "/Standby/";
  
  constructor(private db: AngularFireDatabase) { }

  addStandby(value:standBy):void {
   value.numericDate = value.date.setHours(0,0,0,0);
    this.db.list(this.prefix  + value.numericDate).push(value);
  }

  removeStandBy(item):void{
    this.db.list(this.prefix  +item.key).remove();
  }

  removeStandBysingleItem(date:Date,key):void{
    let numericDate =  date.setHours(0,0,0,0);
    this.db.list(this.prefix + numericDate + "/"+key).remove();
    
  }

  getStandBy():Observable<{}>{
    return this.db.object(this.prefix).valueChanges();
  }

  
  getStandByByDate(date:Date):Observable<{}>{
    let numericDate =  date.setHours(0,0,0,0);
    return this.db.object(this.prefix+numericDate).valueChanges();
  }
}
