import { PhoneLoginComponent } from './phone-login/phone-login.component';
import { AuthService } from './../auth.service';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  isLoginInProgress = false;

  constructor(private auth :AuthService,private dialog: MatDialog) { }


  
  login(){
    this.isLoginInProgress =true;
    this.auth.login().catch(res=>
    {
      this.isLoginInProgress =false;
    }).then(res=>{
      this.isLoginInProgress =false;
    })
 
  }
  
  loginWithFacebook(){
    this.isLoginInProgress =true;
    this.auth.facebookLogin().catch(res=>
      {
        this.isLoginInProgress =false;
      }).then(res=>{
        this.isLoginInProgress =false;
      });
  }

  phoneLogin(){
    
    let dialogRef = this.dialog.open(PhoneLoginComponent, {
       panelClass: 'myapp-no-padding-dialog',
      
    });
  }
}


